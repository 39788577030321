import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "../components/DataTable";
import { Button, styled, IconButton } from "@mui/material";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ScrollToTopButton from '../components/ScrollToTopButton';
import { useWebSocket } from "../utils/WebSocketContext";


const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});

const RefreshButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#008951',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#006837',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        marginBottom: '10px',
        padding: '8px 16px',
        minWidth: 'auto',
        fontSize: '12px',
    },
}));

export default function Game() {
    const {
        gameRecords,
        loading,
    } = useWebSocket();

    const [displayRows, setDisplayRows] = useState([]);
    const [formFields, setFormFields] = useState({
        field1: "",
        field2: "",
        field3: "",
        field4: "",
        field5: "",
        field6: "",
        field7: "",
    });
    const [errors, setErrors] = useState({});
    const [searchRows, setSearchRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreSearch, setHasMoreSearch] = useState(false);
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);

    const navigate = useNavigate();

    // Helper function to format numbers with one decimal place if they are not integers
    const formatNumber = (number) => {
        return typeof number === 'number' && !Number.isInteger(number) ? number.toFixed(1) : number;
    };

    const processReceivedData = useCallback((data) => {
        return data.map((item) => ({
            ...item,
            id: `${item.userId}-${item.gameId}-${item.playedTs}-${Math.random()}`,
            playedTs: item.playedTs ? item.playedTs.split(".")[0] : '',
            balanceBefore: formatNumber(item.balanceBefore),
            balanceAfter: formatNumber(item.balanceAfter),
            totalBet: formatNumber(item.totalBet),
            totalWin: formatNumber(item.totalWin),
        }));
    }, []);

    const fetchInitialData = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`/v1/admin/game-records/initialData`);
            if (response.status === 204) {
                setDisplayRows([]);
            } else {
                const dataWithId = processReceivedData(response.data.data);
                setDisplayRows(dataWithId);
            }
        } catch (error) {
            handle403Error(error, navigate);
        } finally {
            setIsLoading(false);
        }
    }, [navigate, processReceivedData]);

    useEffect(() => {
        if (gameRecords && gameRecords.length > 0) {
            setDisplayRows((prevRows) => {
                const newRecords = gameRecords.filter(
                    (newRecord) =>
                        !prevRows.some(
                            (prevRecord) =>
                                prevRecord.userId === newRecord.userId &&
                                prevRecord.gameId === newRecord.gameId &&
                                prevRecord.playedTs === newRecord.playedTs
                        )
                );
                return [...processReceivedData(newRecords), ...prevRows];
            });
        }
    }, [gameRecords, processReceivedData]);

    const fetchSearchData = async (resetPage = false) => {
        try {
            setIsLoading(true);
            const searchParams = [
                { key: "playerName", value: formFields.field1 },
                { key: "gameName", value: formFields.field2 },
                { key: "balanceBefore", value: formFields.field3 },
                { key: "balanceAfter", value: formFields.field4 },
                { key: "playedOn", value: formFields.field5 },
                { key: "totalBet", value: formFields.field6 },
                { key: "totalWin", value: formFields.field7 },
            ].filter(param => param.value);

            const currentPage = resetPage ? 0 : searchRows.length / 100;
            const response = await api.post(
                `/v1/admin/game-records/search`,
                {
                    page: currentPage,
                    size: 100,
                    searchParams: searchParams,
                }
            );

            if (response.status === 204) {
                if (resetPage) {
                    setSearchRows([]);
                }
                setHasMoreSearch(false);
            } else {
                const dataWithId = processReceivedData(response.data.data);
                setSearchRows((prevRows) => resetPage ? dataWithId : [...prevRows, ...dataWithId]);
                setHasMoreSearch(response.data.data.length > 0);
            }
        } catch (error) {
            handle403Error(error, navigate);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = () => {
        const allFieldsEmpty = Object.values(formFields).every(field => !field);
        if (allFieldsEmpty) {
            alert("Please fill in at least one search field before searching.");
        } else {
            setIsSearchMode(true); // Enable search mode
            fetchSearchData(true); // Fetch search results
        }
    };


    const handleRefresh = () => {
        setIsSearchMode(false);
        setSearchRows([]);
        fetchInitialData();
    };


    const handleCloseSearchForm = () => {
        setFormFields({
            field1: '',
            field2: '',
            field3: '',
            field4: '',
            field5: '',
            field6: '',
            field7: '',
        });
        setIsSearchMode(false); // Disable search mode
        setSearchRows([]); // Clear search results
        fetchInitialData(); // Refresh the table data
    };


    const handleReset = () => {
        setFormFields({
            field1: "",
            field2: "",
            field3: "",
            field4: "",
            field5: "",
            field6: "",
            field7: "",
        });
        setIsSearchMode(false); // Disable search mode
        setSearchRows([]); // Clear search results
        fetchInitialData(); // Refresh the table data
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let errorMessage = "";
        if (["field3", "field4", "field6", "field7"].includes(name) && !/^\d*\.?\d*$/.test(value)) {
            errorMessage = "Please enter a valid number";
        }
        setFormFields({ ...formFields, [name]: value });
        setErrors({ ...errors, [name]: errorMessage });
    };

    const toggleSearchForm = () => {
        setIsSearchFormVisible(!isSearchFormVisible);
    };

    useEffect(() => {
        fetchInitialData();
    }, [fetchInitialData]);

    const columns = [
        { field: "userId", headerName: "User ID", flex: 1, minWidth: 90, sortable: false },
        { field: "username", headerName: "Username", flex: 1, minWidth: 120, sortable: false },
        { field: "gameName", headerName: "Game Name", flex: 1, minWidth: 130, sortable: false },
        { field: "balanceBefore", headerName: "Balance Before", flex: 1, minWidth: 130, sortable: false },
        { field: "balanceAfter", headerName: "Balance After", flex: 1, minWidth: 130, sortable: false },
        { field: "totalBet", headerName: "Total Bet", flex: 1, minWidth: 100, sortable: false },
        { field: "totalWin", headerName: "Total Win", flex: 1, minWidth: 100, sortable: false },
        { field: "playedTs", headerName: "Played On", flex: 1, minWidth: 200, sortable: false },
    ];

    const rows = isSearchMode ? searchRows : displayRows;
    const hasMore = isSearchMode ? hasMoreSearch : false;
    const fetchMoreData = isSearchMode ? fetchSearchData : null;

    return (
        <div className="game">
            <ToastContainer />
            <h1
                className="h3 mb-3"
                style={{ marginBottom: "20px", cursor: "pointer" }}
                onClick={toggleSearchForm}
            >
                <strong>Search</strong>
                <IconButton onClick={handleCloseSearchForm} style={{ marginLeft: '5px', paddingTop: '2px' }}>
                    {isSearchFormVisible ? <CloseIcon /> : <SearchIcon />}
                </IconButton>
            </h1>
            {isSearchFormVisible && (
                <div className="downdiv" style={{ marginBottom: "35px" }}>
                    <form
                        className="searchForm"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                            gap: "10px",
                        }}
                    >
                        <div>
                            <label htmlFor="field1">Player Name</label>
                            <input
                                type="text"
                                id="field1"
                                name="field1"
                                value={formFields.field1}
                                onChange={handleInputChange}
                                style={{ width: "100%", padding: "8px", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field2">Game Name</label>
                            <input
                                type="text"
                                id="field2"
                                name="field2"
                                value={formFields.field2}
                                onChange={handleInputChange}
                                style={{ width: "100%", padding: "8px", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field3">Balance Before</label>
                            <input
                                type="text"
                                id="field3"
                                name="field3"
                                value={formFields.field3}
                                onChange={handleInputChange}
                                className={errors.field3 ? "input-error" : ""}
                                style={{ width: "100%", padding: "8px", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field4">Balance After</label>
                            <input
                                type="text"
                                id="field4"
                                name="field4"
                                value={formFields.field4}
                                onChange={handleInputChange}
                                className={errors.field4 ? "input-error" : ""}
                                style={{ width: "100%", padding: "8px", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field5">Played On</label>
                            <input
                                type="date"
                                id="field5"
                                name="field5"
                                value={formFields.field5}
                                onChange={handleInputChange}
                                style={{ width: "100%", padding: "8px", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field6">Total Bet</label>
                            <input
                                type="text"
                                id="field6"
                                name="field6"
                                value={formFields.field6}
                                onChange={handleInputChange}
                                className={errors.field6 ? "input-error" : ""}
                                style={{ width: "100%", padding: "8px", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field7">Total Win</label>
                            <input
                                type="text"
                                id="field7"
                                name="field7"
                                value={formFields.field7}
                                onChange={handleInputChange}
                                className={errors.field7 ? "input-error" : ""}
                                style={{ width: "100%", padding: "8px", border: "1px solid #999" }}
                            />
                        </div>
                    </form>

                    <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                        <button
                            type="button"
                            onClick={handleSearch}
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#007BFF",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="button"
                            onClick={handleReset}
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#6c757d",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h1 className="h3"><strong>Game Records</strong></h1>
                <RefreshButton onClick={handleRefresh}>Refresh</RefreshButton>
            </div>
            <InfiniteScroll
                dataLength={rows.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={isLoading && <h4>Loading...</h4>}
                endMessage={<p>No more data</p>}
            >
                <DataTable
                    columns={columns}
                    rows={rows}
                    getRowId={(row) => row.id}
                    isLoading={loading}
                />
            </InfiniteScroll>
            <ScrollToTopButton />
        </div>
    );
}
