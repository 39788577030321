import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';  // Import BrowserRouter
import { WebSocketProvider } from './utils/WebSocketContext';


const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>  {/* Move Router here */}
      <WebSocketProvider>
        <App />
      </WebSocketProvider>
    </Router>  {/* Router should wrap WebSocketProvider */}
  </ThemeProvider>
);

reportWebVitals();
