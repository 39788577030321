import React, { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handle403Error } from '../utils/handle403Error'; // Import handle403Error
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VentureTableWrapper = styled.div`
  max-width: 100vw;
  padding: 20px;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 68%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333333;

  @media screen and (max-width: 1400px) {
    width: 100%;
  }

  p {
    font-weight: 700;
    font-size: 20px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
`;

const DataEntry = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 8px;
  line-height: 1.6;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
  }

  .title {
    display: flex;
    align-items: center;
    font-weight: 600;
    width: 20vw;

    @media screen and (max-width: 683px) {
      font-size: 13px;
      width: 95%;
    }
  }

  .detail {
    width: 40vw;

    @media screen and (max-width: 683px) {
      width: 95%;
      font-size: 13px;
    }

    textarea {
      height: 100%;
      width: 100%;
      padding: 10px;
      background-color: transparent;
      color: rgb(0, 0, 0);
      outline: none;
      border: 1px solid rgb(94, 93, 93);
      border-radius: 3px;

      @media screen and (max-width: 480px) {
        width: 95%;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const ErrorText = styled.span`
  color: red;
  font-size: 14px;
  margin-top: 2px;
  display: block;
`;

const SubmitContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;

  button {
    background-color: rgb(184, 181, 181);
    border: 1px solid rgb(163, 162, 162);
    border-radius: 4px;
    width: 10%;
    padding: 5px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      width: 18%;
    }

    &:hover {
      background-color: rgba(190, 187, 187, 0.9);
    }
  }
`;

const VentureTable = ({
    id,
    emailAddress,
    countryAndState,
    investmentRange,
    myTextarea,
    lastEmailResponseUpdate: initialLastEmailResponseUpdate,
    emailResponse: initialEmailResponse,
    onUpdate,
    onClose,
    notifyParent
}) => {
    const [emailResponse, setEmailResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState(""); // Validation state
    const navigate = useNavigate(); // Initialize navigate

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Frontend validation: Check if the input is empty
        if (!emailResponse) {
            setValidationError("Please enter a comment before submitting!");
            return;
        }

        setLoading(true);
        setValidationError(""); // Clear validation error when starting submit

        const encodedEmailResponse = encodeURIComponent(emailResponse);
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/v1/admin/venture-report/update-email-response/${id}?emailResponse=${encodedEmailResponse}`;

        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include' // Ensures cookies are sent with the request
            });

            if (!response.ok) {
                // Check if the error is a 403 error
                if (response.status === 403) {
                    handle403Error(response, navigate); // Handle 403 error
                    return;
                }
                const errorData = await response.json(); // Parse the JSON response
                const errorMessage = errorData.error || "An unexpected error occurred";
                toast.error(errorMessage); // Display only the error message
                setLoading(false);
                return;
            }

            const data = await response.json();
            onUpdate(id, emailResponse);
            notifyParent(data.message || "Email response updated successfully.");
            onClose();
        } catch (error) {
            const message = error.message;
            toast.error(`Error: ${message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setEmailResponse(e.target.value);
        if (validationError) {
            setValidationError(""); // Clear validation error when user starts typing
        }
    };

    return (
        <Overlay>
            <VentureTableWrapper>
                <p>Venture Form</p>
                <DataEntry>
                    <div className="title">ID:</div>
                    <div className="detail">{id}</div>
                </DataEntry>

                <DataEntry>
                    <div className="title">Email:</div>
                    <div className="detail">{emailAddress}</div>
                </DataEntry>

                <DataEntry>
                    <div className="title">Location:</div>
                    <div className="detail">{countryAndState}</div>
                </DataEntry>

                <DataEntry>
                    <div className="title">Investment Range:</div>
                    <div className="detail">{investmentRange}</div>
                </DataEntry>

                <DataEntry>
                    <div className="title">Message:</div>
                    <div className="detail">{myTextarea || "No Message Sent!"}</div>
                </DataEntry>

                <DataEntry>
                    <div className="title">Last Commented on:</div>
                    <div className="detail">{initialLastEmailResponseUpdate || "Not commented yet!"}</div>
                </DataEntry>

                <DataEntry>
                    <div className="title">Last Comment:</div>
                    <div className="detail">
                        <textarea
                            placeholder="Type the reply"
                            value={initialEmailResponse}
                            readOnly
                        ></textarea>
                    </div>
                </DataEntry>

                <DataEntry>
                    <div className="title">Comment:</div>
                    <div className="detail">
                        <textarea
                            placeholder="Write a comment"
                            value={emailResponse}
                            onChange={handleInputChange}
                        ></textarea>
                        {validationError && <ErrorText>{validationError}</ErrorText>} {/* Show validation error */}
                    </div>
                </DataEntry>

                <SubmitContainer>
                    <button onClick={handleSubmit} className="submitBtn" disabled={loading}>
                        {loading ? "Submitting..." : "Submit"}
                    </button>
                    <button onClick={onClose} className="closeBtn">
                        Close
                    </button>
                </SubmitContainer>
            </VentureTableWrapper>
        </Overlay>
    );
};

export default VentureTable;
