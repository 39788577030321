// App.js
import React, { useEffect, useState, useContext, createContext, useCallback } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import UserList from "./pages/UserList";
import Recharge from "./pages/Recharge";
import Redeem from "./pages/Redeem";
import Login from './pages/Login';
import 'react-toastify/dist/ReactToastify.css';
import Gamelist from './pages/Gamelist';
import Profile from './pages/Profile';
import Subdistributor from './pages/Subdistributor';
import Store from './pages/Store';
import Player from './pages/Player';
import Games from './pages/Game';
import Venture from './pages/Ventureresponse';
import Admindashboard from './pages/Admindashboard';
import SlotDetail from './pages/SlotDetail';
import GameOnboarding from './pages/GameOnboarding';
import BroadcastMessage from './pages/BroadcastMessage';
import KenoDetail from './pages/KenoDetail';
import { WebSocketContext } from './utils/WebSocketContext';
import DailyBonusWheel from './pages/DailyBonusWheel';
import StoreDailyBonus from './pages/storeDailyBonus';

// Create an Auth context
const AuthContext = createContext();

const styles = {
    loadingOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Slightly darkened background
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
    },
    casinoSpinner: {
        border: "8px solid rgba(0, 0, 0, 0.1)",
        borderLeftColor: "#FFD700", // Gold color for casino vibe
        borderTopColor: "#FF4500", // A bright casino-like color
        borderRadius: "50%",
        width: "80px",
        height: "80px",
    },
};

function App() {
    const { loading, connectWebSocket } = useContext(WebSocketContext); // Access the WebSocket loading state

    // Set a blank title immediately to prevent any flash during loading
    useEffect(() => {
        document.title = ""; // Or use a placeholder like "Loading..." if preferred
    }, []);

    useEffect(() => {
        // Establish WebSocket connection when the app loads, except for the login page
        if (window.location.pathname !== "/login") {
            connectWebSocket();
        }
    }, [connectWebSocket]);

    return (
        <>
            {loading && window.location.pathname !== "/login" && (
                <div style={styles.loadingOverlay}>
                    <div className="casino-spinner"></div> {/* Add the spinner class here */}
                </div>
            )}

            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/*" element={<PrivateRoute />} />
                </Routes>
            </AuthProvider>

            {/* Inline style for the spinner */}
            <style>
                {`
                    .casino-spinner {
                        border: 8px solid rgba(0, 0, 0, 0.1);
                        border-left-color: #FFD700;
                        border-top-color: #FF4500;
                        border-radius: 50%;
                        width: 80px;
                        height: 80px;
                        animation: spinCasino 1.5s ease infinite;
                    }

                    @keyframes spinCasino {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                `}
            </style>
        </>
    );
}

function AuthProvider({ children }) {
    const [auth, setAuth] = useState({ role: localStorage.getItem('role') });

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
}

function PrivateRoute() {
    const navigate = useNavigate();
    const { auth, setAuth } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);

    const handleLogout = useCallback(() => {
        localStorage.removeItem("role");
        setAuth({});
        window.location.href = "/login";
    }, [setAuth]);

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role) {
            setAuth({ role });
            setLoading(false);
        } else {
            navigate('/login');
        }
    }, [navigate, setAuth]);

    useEffect(() => {
        let logoutTimer;

        const resetTimeout = () => {
            clearTimeout(logoutTimer);
            logoutTimer = setTimeout(() => {
                handleLogout();
            }, 1800000); // 30 minutes
        };

        const handleActivity = () => {
            resetTimeout();
        };

        resetTimeout();

        window.addEventListener("mousemove", handleActivity);
        window.addEventListener("keydown", handleActivity);

        return () => {
            clearTimeout(logoutTimer);
            window.removeEventListener("mousemove", handleActivity);
            window.removeEventListener("keydown", handleActivity);
        };
    }, [handleLogout]);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await fetch('/api/check-auth', { credentials: 'include' }); // Relying on cookies for authentication
                if (response.status === 401) {
                    handleLogout();
                } else {
                    setLoading(false);
                }
            } catch (error) {
                handleLogout();
            }
        };

        checkAuth();
    }, [handleLogout]);

    if (loading) {
        return <div>Loading...</div>; // Loading spinner or any other loading indicator
    }

    return auth.role ? <MainLayout role={auth.role} /> : <Navigate to="/login" />;
}

function MainLayout({ role }) {
    const [resetUserList, setResetUserList] = useState(false);
    const [resetSlotDetail, setResetSlotDetail] = useState(false);
    const [resetKenoDetail, setResetKenoDetail] = useState(false);
    const [resetGameOnboarding, setResetGameOnboarding] = useState(false);

    // Set document title based on the role only if it is not loading
    useEffect(() => {
        if (role) {
            document.title = `${role}-Diamond Casino`;
        }
    }, [role]);

    const handleUsersListClick = () => {
        setResetUserList(true);
    };

    const handleSlotDetailClick = () => {
        setResetSlotDetail(true);
    };

    const handleKenoDetailClick = () => {
        setResetKenoDetail(true);
    };

    const handleGameOnboardingClick = () => {
        setResetGameOnboarding(true);
    };

    return (
        <div className="wrapper">
            <Sidebar
                onUsersListClick={handleUsersListClick}
                onSlotDetailClick={handleSlotDetailClick}
                onGameOnboardingClick={handleGameOnboardingClick}
                onKenoDetailClick={handleKenoDetailClick}
            />
            <div className="main">
                <Navbar />
                <main className="content">
                    <div className="container-fluid p-0">
                        <Routes>
                            {role === 'ADMIN' ? (
                                <>
                                    <Route path="/admin-dashboard" element={<Admindashboard />} />
                                    <Route path="/slot-detail" element={<SlotDetail resetSlotDetail={resetSlotDetail} setResetSlotDetail={setResetSlotDetail} />} />
                                    <Route path="/keno-detail" element={<KenoDetail resetKenoDetail={resetKenoDetail} setResetKenoDetail={setResetKenoDetail} />} />
                                    <Route path="/game-onboarding" element={<GameOnboarding resetGameOnboarding={resetGameOnboarding} setResetGameOnboarding={setResetGameOnboarding} />} />
                                    <Route path="/broadcast-message" element={<BroadcastMessage />} />
                                    <Route path="/ventureresponse" element={<Venture />} />
                                    <Route path="/daily-bonus-wheel" element={<DailyBonusWheel />} />
                                    <Route path="/profile" element={<Profile />} />
                                    <Route path="*" element={<Navigate to="/admin-dashboard" />} />
                                </>
                            ) : (
                                <>
                                    <Route path="/" element={<Dashboard />} />
                                    <Route path="/user-list" element={<UserList resetUserList={resetUserList} setResetUserList={setResetUserList} />} />
                                    <Route path="/recharge" element={<Recharge />} />
                                    <Route path="/redeem" element={<Redeem />} />
                                    <Route path="/gamelist" element={<Gamelist />} />
                                    <Route path="/subdistributor" element={<Subdistributor />} />
                                    <Route path="/players" element={<Player />} />
                                    <Route path="/games" element={<Games />} />
                                    <Route path="/store" element={<Store />} />
                                    <Route path="/profile" element={<Profile />} />
                                    <Route path="*" element={<Navigate to="/" />} />
                                    <Route path="/store-Daily-Bonus" element={<StoreDailyBonus />} />
                                </>
                            )}
                        </Routes>
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    );
}

export default App;
