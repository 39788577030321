import { Switch } from '@mui/material';
import { styled } from '@mui/system';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 22,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(16px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff', // Blue color when switch is on
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgba(0, 35, 11, 0.2)',
        width: 22,
        height: 22,
        borderRadius: 11,
        backgroundColor: '#fff',
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        opacity: 1,
        backgroundColor: '#E50000', // Red color when switch is off
        boxSizing: 'border-box',
        transition: theme.transitions.create(['background-color'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track.Mui-disabled': {
        backgroundColor: '#E50000', // Red color when disabled and off
    },
}));

export default CustomSwitch;
