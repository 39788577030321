import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { useNavigate } from 'react-router-dom';
import { handle403Error } from './handle403Error';

// Create WebSocket Context
export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
    const [balanceMap, setBalanceMap] = useState({});
    const [gameRecords, setGameRecords] = useState([]); // New state for game records
    const [isConnected, setIsConnected] = useState(false);
    const [loading, setLoading] = useState(true);
    const [skipWebSocketCheck, setSkipWebSocketCheck] = useState(false);
    const navigate = useNavigate();
    const managerId = localStorage.getItem("userId");

    // Singleton pattern for WebSocket client
    const clientRef = useRef(null);
    const webSocketConnected = useRef(false);

    // Function to format DateTime
    const formatDateTime = (dateTime) => {
        if (!dateTime) return '';
        return dateTime.split(".")[0];
    };

    // Process received data
    const processReceivedData = useCallback((data) => {
        return data.map((item) => {
            if (!item.id) {
                item.id = `${item.userId}-${item.gameId}-${item.playedTs}`;
            }
            if (item.playedTs) {
                item.playedTs = formatDateTime(item.playedTs);
            }
            return item;
        });
    }, []);

    // Disconnect WebSocket
    const disconnectWebSocket = useCallback(() => {
        if (clientRef.current && clientRef.current.active) {
            clientRef.current.deactivate(() => {
                console.log("WebSocket disconnected");
                sessionStorage.removeItem('webSocketConnected');
                setIsConnected(false);
            });
        }
    }, []);

    // Connect WebSocket (only once)
    const connectWebSocket = useCallback(() => {
        if (webSocketConnected.current) {
            console.log("WebSocket already connected");
            return;
        }

        if (!managerId || skipWebSocketCheck) {
            console.log("Skipping WebSocket connection due to missing login or 403 error.");
            setLoading(false);
            return;
        }

        setLoading(true);
        console.log("Attempting WebSocket connection...");

        try {
            const socket = new SockJS(`${process.env.REACT_APP_API_BASE_URL}/ws`);
            const newClient = new Client({
                webSocketFactory: () => socket,
                onConnect: () => {
                    console.log("WebSocket connected");
                    webSocketConnected.current = true;
                    sessionStorage.setItem('webSocketConnected', 'true');
                    setIsConnected(true);
                    setLoading(false);

                    // Subscribe to balance updates
                    newClient.subscribe('/topic/balance', (response) => {
                        const updatedBalance = JSON.parse(response.body);
                        setBalanceMap((prevMap) => ({
                            ...prevMap,
                            ...updatedBalance,
                        }));
                    });

                    // Subscribe to game records updates
                    newClient.subscribe(`/topic/gameRecords/${managerId}`, (response) => {
                        const updatedRecords = JSON.parse(response.body);
                        const processedRecords = processReceivedData(updatedRecords);

                        setGameRecords((prevRecords) => {
                            // Filter out duplicates from `processedRecords` if they already exist in `prevRecords`
                            const uniqueRecords = processedRecords.filter((newRecord) =>
                                !prevRecords.some((prevRecord) =>
                                    prevRecord.userId === newRecord.userId &&
                                    prevRecord.gameId === newRecord.gameId &&
                                    prevRecord.playedTs === newRecord.playedTs
                                )
                            );
                            return [...uniqueRecords, ...prevRecords];
                        });
                    });

                },
                onStompError: (error) => {
                    console.error('WebSocket connection error:', error);
                    if (error.status === 403) {
                        setSkipWebSocketCheck(true);
                        setLoading(false);
                        handle403Error(error, navigate, disconnectWebSocket);
                    }
                },
            });

            newClient.activate();
            clientRef.current = newClient;
        } catch (error) {
            console.error('Error during WebSocket connection:', error);
            setSkipWebSocketCheck(true);
            setLoading(false);
            handle403Error(error, navigate, disconnectWebSocket);
        }
    }, [skipWebSocketCheck, disconnectWebSocket, navigate, managerId, processReceivedData]);

    useEffect(() => {
        const role = localStorage.getItem("role");
        if (role && !skipWebSocketCheck && managerId) {
            connectWebSocket();
        }
    }, [connectWebSocket, skipWebSocketCheck, managerId]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            disconnectWebSocket();
        };
    }, [disconnectWebSocket]);

    return (
        <WebSocketContext.Provider value={{ balanceMap, gameRecords, connectWebSocket, disconnectWebSocket, loading, isConnected }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
