import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import DataTable from '../components/DataTable';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Box, styled } from "@mui/material";
import CustomSwitch from '../components/CustomSwitch';
import { handle403Error } from '../utils/handle403Error';
import { useNavigate } from 'react-router-dom';
import ScrollToTopButton from '../components/ScrollToTopButton'; // Import the component

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 5000,
  withCredentials: true,
});

const buttonStyles = (theme) => ({
  margin: '8px',
  padding: '8px 15px',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#115293',
  },
  fontSize: '14px',
  [theme.breakpoints.down(650)]: {
    margin: '10px 0',
    padding: '8px 16px',
    minWidth: 'auto',
    fontSize: '12px',
  },
});

const RefreshButton = styled(Button)(({ theme }) => ({
  ...buttonStyles(theme),
  backgroundColor: '#008951',
  '&:hover': {
    backgroundColor: '#006837',
  },
}));

const MainButton = styled(Button)(({ theme }) => ({
  ...buttonStyles(theme),
  backgroundColor: '#3D5B99',
  '&:hover': {
    backgroundColor: '#30487A',
  },
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  ...buttonStyles(theme),
  height: '30px',
  backgroundColor: '#d32f2f',
  '&:hover': {
    backgroundColor: '#9a0007',
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  ...buttonStyles(theme),
  backgroundColor: '#f9a825',
  '&:hover': {
    backgroundColor: '#f57f17',
  },
}));

function KenoDetail({ resetKenoDetail, setResetKenoDetail }) {
  const [rows, setRows] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [toggleRow, setToggleRow] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const initialLoadRef = useRef(true);
  const fileInputRef = useRef(null);
  const closeDetailsButtonRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get("/v2/keno/admin/get-all-keno-info");
      const data = response.data.data
        .filter((item) => item.kenoStatus !== "DELETED")
        .map((item) => ({
          id: item.kenoId,
          kenoName: item.kenoName,
          kenoCode: item.kenoCode,
          kenoStatus: item.kenoStatus,
          rtpPercentage: item.avgRtpPercentage,
          winRate: item.winRate,
        }));
      setRows(data);
    } catch (error) {
      console.error("Error fetching keno data: ", error);
      handle403Error(error, navigate);
    }
    if (initialLoadRef.current) {
      initialLoadRef.current = false;
    }
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (resetKenoDetail) {
      setSelectedGame(null);
      setResetKenoDetail(false);
      fetchData(); // Fetch data again when the "Keno Detail" link is clicked
    }
  }, [resetKenoDetail, setResetKenoDetail, fetchData]);

  const handleRefresh = () => {
    fetchData();
  };

  const handleRowClick = async (params) => {
    if (params.field !== "kenoStatus") {
      try {
        const response = await api.get(`v2/keno/admin/get-keno-info/${params.id}`);
        setSelectedGame(response.data.data);
      } catch (error) {
        console.error("Error fetching game details: ", error);
        handle403Error(error, navigate);
      }
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await api.post(
          "/v2/keno/admin/create-keno-fromfile",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const newKeno = {
          id: response.data.data.kenoId,
          kenoName: response.data.data.kenoName,
          kenoCode: response.data.data.kenoCode,
          kenoStatus: response.data.data.kenoStatus,
          rtpPercentage: response.data.data.rtpPercentage,
          winRate: response.data.data.winRate,
        };

        // Prepend the new keno to the top of the list
        setRows((prevRows) => [newKeno, ...prevRows].filter((row) => row.kenoStatus !== "DELETED"));
        toast.success("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file: ", error);
        handle403Error(error, navigate);
      }
    }
  };


  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleToggleChange = (event, row) => {
    setToggleRow({ row, isActive: event.target.checked });
    setDialogOpen(true);
  };

  const handleCloseDetails = () => {
    setSelectedGame(null);
  };

  const handleOnboardJob = async () => {
    try {
      await api.put("/v2/admin/game-info/start-onboard-job");
      toast.success("Onboard job started successfully");
    } catch (error) {
      console.error("Error starting onboard job: ", error);
      handle403Error(error, navigate);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = async () => {
    setDialogOpen(false);
    if (!toggleRow) return;

    const { row, isActive } = toggleRow;
    const newStatus = isActive ? "ACTIVE" : "INACTIVE";

    try {
      await api.put(`/v2/keno/admin/${isActive ? "activate" : "deactivate"}-keno/${row.id}`);
      toast.success(`Keno ${newStatus.toLowerCase()}d successfully`);
      setRows((prevRows) =>
        prevRows
          .map((r) => (r.id === row.id ? { ...r, kenoStatus: newStatus } : r))
          .filter((r) => r.kenoStatus !== "DELETED")
      );
      if (selectedGame && selectedGame.kenoId === row.id) {
        setSelectedGame((prevGame) => ({
          ...prevGame,
          kenoStatus: newStatus,
        }));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || error.response.data.details || "An unexpected error occurred";
        toast.error(errorMessage);
      } else {
        console.error(`Error ${isActive ? "activating" : "deactivating"} keno: `, error);
        toast.error("An unexpected error occurred");
      }
    }
  };

  const handleDelete = async () => {
    if (!deleteRow) return;

    const { id } = deleteRow;
    try {
      await api.delete(`v2/keno/admin/delete-keno/${id}`);

      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id ? { ...row, kenoStatus: "DELETED" } : row
        ).filter((row) => row.kenoStatus !== "DELETED")
      );

      toast.success("Keno deleted successfully");

      if (selectedGame && selectedGame.kenoId === id) {
        setSelectedGame((prevGame) => ({
          ...prevGame,
          kenoStatus: "DELETED",
        }));
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error deleting keno: ", error);
        toast.error("An unexpected error occurred");
      }
    }
    setDeleteDialogOpen(false);
  };

  const handleDeleteButtonClick = (row) => {
    setDeleteRow(row);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const columns = [
    {
      field: "kenoCode",
      headerName: "Keno Code",
      flex: 1,
      minWidth: 140,
      headerClassName: "custom-header-class",
      cellClassName: "custom-cell-class",
      sortable: false,
    },
    {
      field: "kenoName",
      headerName: "Keno Name",
      flex: 1,
      minWidth: 160,
      headerClassName: "custom-header-class",
      cellClassName: "custom-cell-class",
      sortable: false,
    },
    {
      field: "rtpPercentage",
      headerName: "RTP",
      flex: 1,
      minWidth: 100,
      headerClassName: "custom-header-class",
      cellClassName: "custom-cell-class",
      sortable: false,
    },
    {
      field: "winRate",
      headerName: "Win Rate",
      flex: 1,
      minWidth: 100,
      headerClassName: "custom-header-class",
      cellClassName: "custom-cell-class",
      sortable: false,
    },
    {
      field: "kenoStatus",
      headerName: "Keno Status",
      flex: 1,
      minWidth: 100,
      headerClassName: "custom-header-class",
      cellClassName: "custom-cell-class",
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" width="100%" height="100%">
          <CustomSwitch
            checked={params.value === "ACTIVE"}
            onChange={(event) => handleToggleChange(event, params.row)}
            color="primary"
            onClick={(event) => event.stopPropagation()}
          />
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 140,
      headerClassName: "custom-header-class",
      cellClassName: "custom-cell-class",
      sortable: false,
      renderCell: (params) =>
        params.row.kenoStatus === "DELETED" ? (
          <span className="deletedText">Deleted</span>
        ) : (
          <DeleteButton
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteButtonClick(params.row);
            }}
          >
            Delete
          </DeleteButton>
        ),
    },
  ];

  return (
    <div className="kenoDetail">
      <ToastContainer />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {toggleRow?.isActive ? "Activate Keno" : "Deactivate Keno"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to ${toggleRow?.isActive ? "activate" : "deactivate"} this keno?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleDialogConfirm} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Keno</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this keno?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <h1 className="h3"><strong>Keno Details</strong></h1>
        </div>
        <MainButton style={{ marginRight: 0 }} onClick={handleOnboardJob}>
          Start Onboard Job
        </MainButton>
      </div>
      <div className="userRefresh">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
        <div className="sideDiv" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="buttonDiv">
            <UploadButton className="onboardButton" style={{ marginLeft: 0 }} onClick={handleUploadButtonClick}>
              Upload Keno File
            </UploadButton>
          </div>
          <div
            className="styleMain"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span
              onClick={handleRefresh}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <RefreshButton style={{ marginRight: 0, paddingRight: '10px' }}>Refresh</RefreshButton>
            </span>
          </div>
        </div>

      </div>
      {selectedGame && (
        <div className="gameDetails">
          <div className="kenoMainDiv">
            <h3>Keno Details</h3>
            <button
              className="closeButton"
              onClick={handleCloseDetails}
              ref={closeDetailsButtonRef}
            >
              X
            </button>
          </div>
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{selectedGame.kenoId}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{selectedGame.kenoName}</td>
              </tr>
              <tr>
                <td>Code</td>
                <td>{selectedGame.kenoCode}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{selectedGame.kenoStatus}</td>
              </tr>
              <tr>
                <td>RTP</td>
                <td>{selectedGame.avgRtpPercentage}</td>
              </tr>
              <tr>
                <td>Win Rate</td>
                <td>{selectedGame.winRate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <DataTable columns={columns} rows={rows} onRowClick={handleRowClick} isEmpty={rows.length === 0} isLoading={loading} />
      <ScrollToTopButton />
      <style jsx="true">{`

.kenoDetail {

  .userRefresh {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    width: auto;

    .texts {
      display: flex;
      align-items: center;

      @include md {
        width: 100%;
        margin: 0px;
        justify-content: space-between;
      }

      @include sm {
        flex-direction: row;
        width: 100%;
        margin: 0px;
        justify-content: space-between;
      }

      h2 {
        margin-right: 20px;
      }

    }

    .sideDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;


      .buttonDiv {
        margin-right: 20px;

        .onboardButton {
          padding: 8px 16px;
          background-color: #2196f3;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;

          &:hover {
            background-color: #0b7dda;
          }
        }
      }

      .styleMain {
        .refreshButton {
          font-size: 14px;
          color: white;
          margin-right: 8px;
        }
      }
    }
  }

  .gameDetails {
  width: auto;
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .kenoMainDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      h3 {
        margin: 0;
      }

      .closeButton {
        background-color: #f44336;
        color: white;
        border: none;
        border-radius: 20%;
        cursor: pointer;
        width: 25px;
        padding: 2px;

        &:hover {
          background-color: #d32f2f;
        }
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 8px 12px;
        border: 1px solid #ddd;
        text-align: left;
      }

      th {
        background-color: #f2f2f2;
        font-weight: bold;
      }

      tr:nth-child(even) {
        background-color: #f9f9f9;
      }

      tr:hover {
        background-color: #f1f1f1;
      }
    }
  }

  .deletedText {
    font-size: 12px;
    border-radius: 4px;
    padding: 6px 18px;
    color: rgb(255, 255, 255);
    background-color: black;
  }
}

.userRefresh {
  display: flex;
  justify-content: space-between;

  .sideDiv {
    display: flex;
  }

  .buttonDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  

  svg {
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      transform: rotate(360deg);
    }
  }

  .texts {
    margin: 10px;
    display: flex;
    gap: 7px;
    margin-top: 10px;
    width: 100%;

    @include sm {
      flex-direction: column;
      gap: 10px;

      h2 {
        font-size: larger;
      }
    }

    @include ms {
      flex-direction: column;
      gap: 10px;

      h2 {
        font-size: larger;
      }
    }

    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;

      @include md {
        font-size: large;
      }
    }

    
  }

  .mainButton:hover {
    background-color: #cbcdf1;
  }

  .searchAndRefresh {
    display: flex;
    margin-bottom: 5px;

    .styleMain {
      @include sm {
        margin-top: 5vh;
      }

      @include ms {
        margin-top: 5vh;
      }

      span {
        .refreshButton {
          cursor: pointer;
          display: flex;
          align-items: center;
          transition: color 0.3s;

          &:hover {
            color: #c1bedb;
          }

          svg {
            cursor: pointer;
            transition: transform 0.3s;

            &:hover {
              transform: rotate(360deg);
              /* Rotate on hover */
            }
          }
        }
      }
    }
  }
}

.gameDetails {
  width: 77vw;
  margin: auto;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  color: black;

  @include xxl {
    margin: auto;
    width: 95vw;
    margin-bottom: 20px;
  }

  @include xl {
    margin: auto;
    width: 95vw;
    margin-bottom: 20px.
  }

  @include lg {
    margin: auto.
    width: 95vw.
    margin-bottom: 20px.
  }

  @include md {
    margin: auto.
    width: 93vw.
    margin-bottom: 20px.
  }

  @include sm {
    margin: auto.
    width: 95vw.
    margin-bottom: 20px.
  }
}

.gameDetails h3 {
  font-size: 1.5em.
  color: black.
}

.gameDetails table {
  width: 100%.
  border-collapse: collapse.
}

.gameDetails table th,
.gameDetails table td {
  padding: 8px 12px.
  border: 1px solid #ddd.
  text-align: left.
  color: black.
}

.gameDetails table th {
  background-color: #f2f2f2.
  font-weight: bold.
  color: black.
}

.gameDetails table tr:nth-child(even) {
  background-color: #f9f9f9.
}

.gameDetails table tr:hover {
  background-color: #f1f1f1.
}

.gameDiv {
  padding: 10px.
  margin-bottom: 10px.
  gap: 10px.
  display: flex.
  flex-direction: column.
  justify-content: space-between.

  .closeButton {
    width: 20px.
  }
}

.kenoMainDiv {
  display: flex.
  flex-direction: row.
  justify-content: space-between.
  margin-bottom: 10px.

  .closeButton {
    width: 20px.
  }
}

.updateButton {
  background-color: #d6d7dd.
  color: #333333.
  border: none.
  padding: 7px 20px.
  font-size: 16px.
  font-weight: 600.
  cursor: pointer.
  border-radius: 5px.
  transition: background-color 0.3s, color 0.3s, border-color 0.3s.
}

.updateButton:hover {
  background-color: #cbcdf1.
}

.mainText {
  display: flex.
  flex-direction: row.
  justify-content: space-between.
  align-items: center.
}
`}</style>
    </div>
  );
}

export default KenoDetail;
