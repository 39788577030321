import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import DataTable from '../components/DataTable';
import { useNavigate } from 'react-router-dom';
import { Button, styled, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});

const RefreshButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#008951',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#006837',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        marginBottom: '10px',
        padding: '8px 16px',
        minWidth: 'auto',
        fontSize: '12px',
    },
}));

const ActionButton = styled(Button)(({ theme }) => ({
    margin: '0 5px',
    padding: '3px 8px',
    backgroundColor: '#007BFF',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0056b3',
    },
}));

const DailyBonusWheel = () => {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [editRowData, setEditRowData] = useState(null); // Stores the row being edited
    const [open, setOpen] = useState(false); // Controls the dialog open/close state
    const [editableData, setEditableData] = useState({ value: '', winStatus: '' }); // Editable data for the form
    const [isSaving, setIsSaving] = useState(false); // Tracks if the save process is ongoing
    const [originalValue, setOriginalValue] = useState(''); // Tracks the original reward value
    const inputRef = useRef(null); // Reference to the reward input field

    const navigate = useNavigate();

    const columns = [
        { field: 'id', headerName: 'Id', flex: 1, minWidth: 100 },
        {
            field: 'value',
            headerName: 'Reward',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'winStatus',
            headerName: 'Win Status',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                const { winStatus } = params.row;
                const isSuccess = winStatus === 'SUCCESS';
                const isFail = winStatus === 'FAIL';

                return (
                    <div
                        style={{
                            color: isSuccess ? 'green' : isFail ? 'red' : 'inherit',
                            fontWeight: 'bold',
                        }}
                    >
                        {winStatus}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <ActionButton onClick={() => handleEdit(params.row)}>Edit</ActionButton>
            ),
        },
    ];

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/v2/daily-bonus-wheel/info');
            const { data, code, success } = response.data;

            if (code === 200 && success) {
                const formattedRows = data.map((item) => ({
                    id: item.id,
                    value: item.value,
                    winStatus: item.winStatus,
                }));
                setRows(formattedRows);
                setIsEmpty(formattedRows.length === 0);
            } else {
                setIsEmpty(true);
            }
        } catch (error) {
            handle403Error(error, navigate);
            setIsEmpty(true);
        } finally {
            setIsLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleEdit = (rowData) => {
        setEditRowData(rowData); // Store the row data for the form
        setEditableData({ value: rowData.value, winStatus: rowData.winStatus }); // Set the initial editable values
        setOriginalValue(rowData.value); // Track the original value for comparison
        setOpen(true); // Open the dialog form

        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus(); // Focus the reward input
                inputRef.current.setSelectionRange(rowData.value.length, rowData.value.length); // Move cursor to the end
            }
        }, 100); // Ensure the input is rendered first before focusing
    };

    const handleClose = () => {
        setOpen(false); // Close the dialog form
        setEditRowData(null); // Clear the row data
    };

    const handleSave = async () => {
        if (isSaving || editableData.value === originalValue) return; // Prevent API call if no change is made

        setIsSaving(true); // Set saving state to true
        try {
            // Validate if the reward value is numeric
            const cleanedValue = editableData.value.replace(/\s+/g, '');
            const isNumeric = /^[0-9]+(\.[0-9]+)?$/.test(cleanedValue);
            const finalWinStatus = isNumeric ? 'SUCCESS' : 'FAIL';

            // API call to update the row data
            const response = await api.put('/v2/daily-bonus-wheel/update/wheel', {
                id: editRowData.id,
                value: editableData.value,
                winStatus: finalWinStatus,
            });

            const { message, data } = response.data;

            if (response.data.success) {
                // Update the row data after the API call is successful
                const updatedRows = rows.map((row) =>
                    row.id === editRowData.id
                        ? { ...row, value: data.value, winStatus: data.winStatus }
                        : row
                );
                setRows(updatedRows);
                setOpen(false);
                setEditRowData(null); // Reset edit state
                toast.success(message); // Display success toast
            } else {
                toast.error('Failed to update the wheel');
            }
        } catch (error) {
            handle403Error(error, navigate);
            toast.error('Failed to save data');
        } finally {
            setIsSaving(false); // Reset saving state after API call
        }
    };

    const handleInputChange = (field, value) => {
        setEditableData((prev) => ({ ...prev, [field]: value }));
    };

    return (
        <div>
            <ToastContainer />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h1 className="h3"><strong>Daily Bonus Wheel Rewards</strong></h1>
                <RefreshButton onClick={fetchData}>Refresh</RefreshButton>
            </div>
            <DataTable
                columns={columns}
                rows={rows}
                isEmpty={isEmpty}
                isLoading={isLoading}
                getRowId={(row) => row.id}
            />

            {/* Dialog Form */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Reward</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="ID"
                        type="text"
                        fullWidth
                        value={editRowData?.id || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                        style={{ opacity: 0.5 }} // Fade effect for non-editable fields
                    />
                    <TextField
                        margin="dense"
                        label="Reward"
                        type="text"
                        fullWidth
                        value={editableData.value}
                        onChange={(e) => handleInputChange('value', e.target.value)}
                        inputRef={inputRef} // Attach the ref to the reward input
                    />
                    <TextField
                        margin="dense"
                        label="Win Status"
                        type="text"
                        fullWidth
                        value={editableData.winStatus}
                        InputProps={{
                            readOnly: true,
                        }}
                        style={{ opacity: 0.5 }} // Fade effect for non-editable fields
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" disabled={isSaving || editableData.value === originalValue}>
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DailyBonusWheel;
