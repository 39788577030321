import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true, // Relying on cookies for authentication
});

function Dashboard() {
    const [data, setData] = useState(null);
    const [role] = useState(localStorage.getItem("role") || "USER");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("/v1/admin/home");

                const { data, success, error } = response.data;

                if (success) {
                    setData(data);
                } else {
                    toast.error(error || "An error occurred while fetching data.");
                }
            } catch (error) {
                handle403Error(error, navigate);
            }
        };

        fetchData();
    }, [navigate]);

    const getTitle = (baseTitle) => {
        switch (role) {
            case "STORE":
                return baseTitle.replace("Users", "Players");
            case "SUB_DISTRIBUTOR":
                return baseTitle.replace("Users", "Store");
            case "DISTRIBUTOR":
                return baseTitle.replace("Users", "Sub Distributor");
            case "MASTER":
                return baseTitle.replace("Users", "Distributor");
            default:
                return baseTitle;
        }
    };

    return (
        <div>
            <ToastContainer />
            <h1 className="h3 mb-3"><strong>Analytics Dashboard</strong></h1>
            <div className="row">
                <div className="col-xl-6 col-xxl-5 d-flex">
                    <div className="w-100">
                        <div className="row">
                            {data?.numberOfRechargeToday !== undefined && (
                                <div className="col-sm-6 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">Number Of Recharge Today</h5>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">{data.numberOfRechargeToday}</h1>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {data?.rechargeAmountToday !== undefined && (
                                <div className="col-sm-6 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">Recharge Amount Today</h5>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">$ {data.rechargeAmountToday}</h1>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {data?.numberOfReedemToday !== undefined && (
                                <div className="col-sm-6 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">Number Of Redeem Today</h5>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">{data.numberOfReedemToday}</h1>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {data?.reedemAmountToday !== undefined && (
                                <div className="col-sm-6 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">Redeem Amount Today</h5>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">$ {data.reedemAmountToday}</h1>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-xl-6 col-xxl-7 d-flex">
                    <div className="w-100">
                        <div className="row">
                            {data?.totalNumberOfUsers !== undefined && (
                                <div className="col-sm-12 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">{getTitle("Total Users")}</h5>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">{data.totalNumberOfUsers}</h1>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {data?.numberOfPlayerRegisteredToday !== undefined && (
                                <div className="col-sm-6 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">{getTitle("Users Registered Today")}</h5>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">{data.numberOfPlayerRegisteredToday}</h1>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {data?.numberOfPlayerRegisteredYesterday !== undefined && (
                                <div className="col-sm-6 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">{getTitle("Users Registered Yesterday")}</h5>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">{data.numberOfPlayerRegisteredYesterday}</h1>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                {data?.numberOfRechargeYesterday !== undefined && (
                    <div className="col-12 col-md-6 col-xxl-3 d-flex">
                        <div className="card flex-fill">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Number Of Recharge Yesterday</h5>
                            </div>
                            <div className="card-body">
                                <h1 className="mt-1 mb-3">{data.numberOfRechargeYesterday}</h1>
                            </div>
                        </div>
                    </div>
                )}

                {data?.rechargeAmountYesterday !== undefined && (
                    <div className="col-12 col-md-6 col-xxl-3 d-flex">
                        <div className="card flex-fill">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Recharge Amount Yesterday</h5>
                            </div>
                            <div className="card-body">
                                <h1 className="mt-1 mb-3">$ {data.rechargeAmountYesterday}</h1>
                            </div>
                        </div>
                    </div>
                )}

                {data?.numberOfReedemYesterday !== undefined && (
                    <div className="col-12 col-md-6 col-xxl-3 d-flex">
                        <div className="card flex-fill">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Number Of Redeem Yesterday</h5>
                            </div>
                            <div className="card-body">
                                <h1 className="mt-1 mb-3">{data.numberOfReedemYesterday}</h1>
                            </div>
                        </div>
                    </div>
                )}

                {data?.reedemAmountYesterday !== undefined && (
                    <div className="col-12 col-md-6 col-xxl-3 d-flex">
                        <div className="card flex-fill">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Redeem Amount Yesterday</h5>
                            </div>
                            <div className="card-body">
                                <h1 className="mt-1 mb-3">$ {data.reedemAmountYesterday}</h1>
                            </div>
                        </div>
                    </div>
                )}

                {role === "MASTER" && data?.dailyBonusSum !== undefined && (
                    <div className="col-sm-3 d-flex">
                        <div className="card flex-fill">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col mt-0">
                                        <h5 className="card-title">{getTitle("Daily Bonus Sum")}</h5>
                                    </div>
                                </div>
                                <h1 className="mt-1 mb-3">$ {data.dailyBonusSum}</h1>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Dashboard;
