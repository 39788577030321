import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import DataTable from '../components/DataTable';
import { useNavigate } from 'react-router-dom';
import { Button, styled } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error'; // Import the utility function
import ScrollToTopButton from '../components/ScrollToTopButton'; // Import the component

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true, // Ensure credentials are sent with the request
});

const RefreshButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#008951',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#006837',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        marginBottom: '10px',
        padding: '8px 16px',
        minWidth: 'auto',
        fontSize: '12px',
    },
}));

const Games = () => {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [showNoMoreData, setShowNoMoreData] = useState(false); // Track if we should show "No more data"
    const navigate = useNavigate();

    const columns = [
        { field: 'gameId', headerName: 'ID', flex: 1, minWidth: 300 },
        { field: 'gameName', headerName: 'Game Name', flex: 1, minWidth: 150 },
        { field: 'gameCode', headerName: 'Game Code', flex: 1, minWidth: 150 },
        { field: 'status', headerName: 'Status', flex: 1, minWidth: 150 },
        { field: 'winRate', headerName: 'Win Rate', flex: 1, minWidth: 120 },
        { field: 'rtp', headerName: 'RTP', flex: 1, minWidth: 100 },
        { field: 'gameType', headerName: 'Game Type', flex: 1, minWidth: 150 },
        { field: 'gameSubType', headerName: 'Game Sub Type', flex: 1, minWidth: 150 },
    ];

    const fetchData = useCallback(async () => {
        const fetchSlotInfo = async () => {
            try {
                const response = await api.get('/v2/slot/admin/get-all-slots-info');
                return response.data.data;
            } catch (error) {
                console.error('Failed to fetch slot info', error);
                return [];
            }
        };

        try {
            const [gameResponse, slotInfo] = await Promise.all([
                api.get("/v2/admin/game-info/all-games"),
                fetchSlotInfo()
            ]);

            const { data, code, success } = gameResponse.data;

            if (code !== 200 || !success) {
                setIsEmpty(true);
                setIsLoading(false);
                return;
            }

            const rowsWithId = data.map((row, index) => {
                const slotData = slotInfo.find(slot => slot.slotId === row.parentId) || {};
                return {
                    ...row,
                    id: row.gameId || index,
                    winRate: slotData.winRate,
                    rtp: slotData.rtpPercentage,
                };
            });

            setRows(rowsWithId);
            setIsEmpty(data.length === 0);
        } catch (error) {
            handle403Error(error, navigate);
        } finally {
            setIsLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Track the scroll position to determine when to show "No more data"
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight / 2) { // 50vh condition
                setShowNoMoreData(true);
            } else {
                setShowNoMoreData(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div>
            <ToastContainer />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1 className="h3"><strong>Games</strong></h1>
                </div>
                <RefreshButton style={{ marginRight: 0, paddingRight: '10px' }} onClick={fetchData}>Refresh</RefreshButton>
            </div>
            <DataTable
                columns={columns}
                rows={rows}
                isEmpty={isEmpty}
                isLoading={isLoading}
                getRowId={(row) => row.id}
            />
            {showNoMoreData && isEmpty && !isLoading && (
                <p>No more data</p>
            )}
            <ScrollToTopButton />
        </div>
    );
};

export default Games;
