import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

/**
 * @typedef {Object} Props
 * @property {GridColDef[]} columns
 * @property {Object[]} rows
 * @property {(params: GridRowParams) => void} onRowClick
 * @property {(row: any) => any} [getRowId]
 * @property {boolean} isEmpty
 * @property {boolean} isLoading
 * @property {boolean} isUserDetails
 * @property {number} rowCount
 * @property {() => Promise<Object[]>} fetchMoreRows Function to fetch more rows
 */

/**
 * @param {Props} props
 */
const DataTable = (props) => {
  const { onRowClick, rows, columns, getRowId, isLoading, isEmpty, rowCount, fetchMoreRows } = props;

  const [clickedRow, setClickedRow] = useState(null);

  const handleRowClick = (params) => {
    setClickedRow(params.id);
    if (onRowClick) {
      onRowClick(params);
    }
  };

  const handleScroll = useCallback(async (params) => {
    const bottom = params.clientHeight + params.scrollTop === params.scrollHeight;
    if (bottom && !isLoading) {
      await fetchMoreRows();
    }
  }, [isLoading, fetchMoreRows]);

  useEffect(() => {
    const container = document.querySelector('.data-grid-container .MuiDataGrid-virtualScroller');
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <div className="data-grid-container" style={{ width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        getRowId={getRowId}
        getRowClassName={(params) =>
          params.id === clickedRow ? 'clickedRow' : (params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow')
        }
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: false,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        autoHeight
        localeText={{
          noRowsLabel: isLoading ? "" : (isEmpty ? "No data found!" : ""),
        }}
        sx={{
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.75)'
          },
          '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader, & .MuiDataGrid-row': {
            cursor: 'default',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'rgba(225, 226, 238, 0.8)',
          },
          '& .clickedRow': {
            backgroundColor: 'rgba(225, 226, 238, 0.8)',
          },
          '& .evenRow': {
            backgroundColor: 'rgba(240, 240, 240, 0.8)',
          },
          '& .oddRow': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          },
          '& .MuiDataGrid-overlay': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1.2em',
            fontWeight: 'bold',
          },
        }}
        rowCount={rowCount}  // This should always be a valid integer
        loading={isLoading}
        paginationMode="server"
        pageSize={100} // Adjust as needed
        hideFooter // Hides the pagination footer
      />
    </div>
  );
};

export default DataTable;
