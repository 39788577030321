import { toast } from 'react-toastify';

// Ensure the WebSocketContext or WebSocket client is available to handle WebSocket disconnection
export const handle403Error = (error, navigate, disconnectWebSocket) => {
    if (error.response && error.response.status === 403) {
        // Clear the WebSocket connection
        if (disconnectWebSocket) {
            disconnectWebSocket(); // Disconnect the WebSocket connection
            console.log("WebSocket connection closed due to session expiration.");
        }

        // Clear all browser caches
        if ('caches' in window) {
            caches.keys().then((cacheNames) => {
                cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                });
            });
        }

        // Clear local storage to remove any session information
        localStorage.clear();

        // Immediately redirect the user to the login page with the session expiration message
        navigate('/login', {
            state: {
                errorMessage: 'Your session has expired. Please log in again.',
                notificationShown: false, // Set this to false to allow future notifications if needed
            },
        });
    } else if (error.response && error.response.status === 404) {
        console.warn('404 error occurred, no toast notification will be shown.');
    } else {
        const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
        console.error('Error fetching data:', error);
        toast.error(errorMessage);
    }
};
