import {
    Button,
} from '@mui/material';

import { styled } from '@mui/system';

import 'react-toastify/dist/ReactToastify.css';

const CustomButton = styled(Button)(({ theme }) => ({
    flex: 1,
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover': {
        backgroundColor: '#115293',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        padding: '8px 16px',
        minWidth: 'auto',
        width: '100%',
        fontSize: '12px',
    },
}));

const ReportForm = ({ onClose, userId, playerName, totalBalance }) => {

    const role = localStorage.getItem("role");

    const getUserName = () => {
        if (role === "STORE") {
            return "Player Name";
        } else if (role === "SUB_DISTRIBUTOR") {
            return "Store Name";
        } else if (role === "DISTRIBUTOR") {
            return "Sub-Dist Name";
        } else if (role === "MASTER") {
            return "Distributor Name";
        }
    };

    const getUserId = () => {
        if (role === "STORE") {
            return "Player Id";
        } else if (role === "SUB_DISTRIBUTOR") {
            return "Store Id";
        } else if (role === "DISTRIBUTOR") {
            return "Sub-Dist Id";
        } else if (role === "MASTER") {
            return "Distributor Id";
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    };

    const styles = {
        form: {
            width: '300px',
            padding: '20px',
            background: 'white',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: 2,
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        inputGroup: {
            marginBottom: '15px',
        },
        label: {
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            fontSize: '14px',
        },
        input: {
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ced4da',
        },
        textArea: {
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ced4da',
            resize: 'vertical',
            minHeight: '100px',
        },
        errorText: {
            color: 'red',
            fontSize: '12px',
        },
        buttonGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        button: {
            flex: 1,
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            marginBottom: '10px',
        },
        submitButton: {
            backgroundColor: '#007bff',
            color: 'white',
            marginRight: '0',
        },
        cancelButton: {
            backgroundColor: '#f44336',
            color: 'white',
            border: '1px solid #ced4da',
        },
        buttonGroupRow: {
            flexDirection: 'row',
        },
        buttonMarginRight: {
            marginRight: '10px',
        },
    };

    const isDesktop = window.innerWidth >= 600;

    return (
        <div style={styles.form}>
            <h2 style={styles.header}>Report Form</h2>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="userId">{getUserId()}</label>
                <input id="userId" type="text" value={userId} style={styles.input} readOnly />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="playerName">{getUserName()}</label>
                <input id="playerName" type="text" value={playerName} style={styles.input} readOnly />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="report">Report</label>
                <textarea id="report" style={styles.textArea}></textarea>
            </div>
            <div style={{ ...styles.buttonGroup, ...(isDesktop ? styles.buttonGroupRow : {}) }}>
                <CustomButton
                    type="submit"
                    style={{ ...styles.button, ...styles.submitButton, ...(isDesktop ? styles.buttonMarginRight : {}) }}
                    onClick={handleSubmit}
                >
                    Submit
                </CustomButton>
                <CustomButton type="button" style={{ ...styles.button, ...styles.cancelButton }} onClick={onClose}>
                    Cancel
                </CustomButton>
            </div>
        </div>
    );
};

export default ReportForm;
