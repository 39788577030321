import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { handle403Error } from '../utils/handle403Error';
import { useNavigate } from 'react-router-dom';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
    timeout: 5000,
    withCredentials: true,
});

function AdminDashboard() {
    const [activeSlotCount, setActiveSlotCount] = useState(0);
    const [activeUploadedGames, setActiveUploadedGames] = useState(0);
    const [activeKenoCount, setActiveKenoCount] = useState(0);
    const [averageBetSizePerGame, setAverageBetSizePerGame] = useState({});
    const [getWinLossRatio, setGetWinLossRatio] = useState([]);
    const [gamePopularity, setGamePopularity] = useState({});
    const [getKpis, setGetKpis] = useState({});
    const [openSection, setOpenSection] = useState(null);
    const [isMobileView, setIsMobileView] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSlotData = async () => {
            try {
                const response = await api.get("v2/slot/admin/get-all-slots-info");
                const activeSlots = response.data.data.filter(item => item.slotStatus === "ACTIVE");
                setActiveSlotCount(activeSlots.length);
            } catch (error) {
                handle403Error(error, navigate);
            }
        };

        const fetchUploadedGames = async () => {
            try {
                const response = await api.get("v2/admin/game-info/all-games");
                const activeGames = response.data.data.filter(item => item.status === "ACTIVE");
                setActiveUploadedGames(activeGames.length);
            } catch (error) {
                handle403Error(error, navigate);
            }
        };

        const fetchKenoData = async () => {
            try {
                const response = await api.get("/v2/keno/admin/get-all-keno-info");
                const activeKeno = response.data.data.filter(item => item.kenoStatus === "ACTIVE");
                setActiveKenoCount(activeKeno.length);
            } catch (error) {
                handle403Error(error, navigate);
            }
        };

        fetchSlotData();
        fetchUploadedGames();
        fetchKenoData();
    }, [navigate]);

    const fetchAverageBetSize = async () => {
        try {
            const response = await axios.get("https://analytics.dit.hsuk.dev/kpi/getAverageBetSizePerGame");
            setAverageBetSizePerGame(response.data.data);
        } catch (error) {
            console.error("Error fetching average bet size per game:", error);
        }
    };

    const fetchWinLossRatio = async () => {
        try {
            const response = await axios.get("https://analytics.dit.hsuk.dev/kpi/getWinLossRatio");
            setGetWinLossRatio(response.data.data);
        } catch (error) {
            console.error("Error fetching win/loss ratio:", error);
        }
    };

    const fetchGamePopularity = async () => {
        try {
            const response = await axios.get("https://analytics.dit.hsuk.dev/kpi/gamePopularity");
            setGamePopularity(response.data.data);
        } catch (error) {
            console.error("Error fetching game popularity:", error);
        }
    };

    const fetchKpis = async () => {
        try {
            const response = await axios.get("https://analytics.dit.hsuk.dev/kpi/getKpis");
            setGetKpis(response.data.data);
        } catch (error) {
            console.error("Error fetching KPIs:", error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 992);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSectionToggle = (section) => {
        setOpenSection(prevOpenSection => (prevOpenSection === section ? null : section));

        if (section === "averageBetSize") {
            fetchAverageBetSize();
        } else if (section === "winLossRatio") {
            fetchWinLossRatio();
        } else if (section === "gamePopularity") {
            fetchGamePopularity();
        } else if (section === "kpis") {
            fetchKpis();
        }
    };

    return (
        <div style={styles.adminDashboard}>
            <ToastContainer />
            <h1 className="h3 mb-3"><strong>Analytics Dashboard</strong></h1>

            {/* Total Active Slots, Keno, and Games */}
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Total Active Slots</h5>
                            <h1 className="mt-1 mb-3">{activeSlotCount}</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Total Active Keno</h5>
                            <h1 className="mt-1 mb-3">{activeKenoCount}</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Total Active Games</h5>
                            <h1 className="mt-1 mb-3">{activeUploadedGames}</h1>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section Headings and Tables */}
            <div className={`row mt-4`} style={styles.headingsRow}>
                {[
                    { title: "Average Bet Size Per Game", section: "averageBetSize", data: averageBetSizePerGame },
                    { title: "Win/Loss Ratio", section: "winLossRatio", data: getWinLossRatio },
                    { title: "Game Popularity", section: "gamePopularity", data: gamePopularity },
                    { title: "KPIs", section: "kpis", data: getKpis },
                ].map(({ title, section, data }) => (
                    <div key={section} className="col-lg-3 col-md-12 col-12">
                        <div
                            className="card"
                            style={openSection === section ? styles.activeCard : styles.clickableCard}
                            onClick={() => handleSectionToggle(section)}
                        >
                            <div className="card-body">
                                <h5 className="card-title">{title} <i className="fa-solid fa-angle-down"></i></h5>
                            </div>
                        </div>
                        {/* Render the table or list below the heading */}
                        {openSection === section && (
                            <div className="col-12" style={styles.tableContainer}>
                                <div style={isMobileView ? styles.tableWrapper : {}}>
                                    {Array.isArray(data) ? (
                                        <table className="table table-bordered mt-4" style={styles.table}>
                                            <thead>
                                                <tr>
                                                    {section === "winLossRatio" ? (
                                                        <>
                                                            <th>Game Name</th>
                                                            <th>Total Wins</th>
                                                            <th>Total Losses</th>
                                                            <th>Win Percentage</th>
                                                            <th>Loss Percentage</th>
                                                        </>
                                                    ) : (
                                                        <th>{section === "gamePopularity" ? "Game Name" : "KPI"}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index}>
                                                        {section === "winLossRatio" ? (
                                                            <>
                                                                <td>{item.gameName}</td>
                                                                <td>{item.totalWins}</td>
                                                                <td>{item.totalLoss}</td>
                                                                <td>{item.winPercentage}%</td>
                                                                <td>{item.lossPercentage}%</td>
                                                            </>
                                                        ) : (
                                                            <td>{section === "gamePopularity" ? item[0] : item[0]}</td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <ul className="list-group mt-1 mb-3">
                                            {Object.entries(data).map(([key, value]) => (
                                                <li key={key} className="list-group-item d-flex justify-content-between align-items-center">
                                                    {key}
                                                    <span className={`badge ${section === "gamePopularity" ? "bg-info" : "bg-secondary"} rounded-pill`}>{value}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

const styles = {
    adminDashboard: {},
    headingsRow: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    clickableCard: {
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        backgroundColor: '#f8f9fa',
        border: '1px solid #ddd',
        marginBottom: '10px',
    },
    activeCard: {
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        backgroundColor: '#d8d8d8',
        color: '#fff',
        border: '1px solid #d8d8d8',
    },
    tableContainer: {
        width: '100%',
        marginTop: '10px',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        minWidth: '600px',
    },
    '@media (max-width: 992px)': {
        headingsRow: {
            flexDirection: 'column', // Stack headings as a column on tablets and smaller devices
        },
        card: {
            width: '100%', // Ensure cards take full width in column layout
        },
    },
    '@media (max-width: 768px)': {
        table: {
            width: '100%',
            minWidth: '100%',
        },
    },
};

export default AdminDashboard;
