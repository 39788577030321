import React, { useState, useCallback } from 'react';
import axios from 'axios';
import DataTable from '../components/DataTable';
import { useNavigate } from 'react-router-dom';
import { Button, styled, TextField, Box } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});

const SearchButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#008951',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#006837',
    },
}));

const ResetButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#d32f2f',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#9a0007',
    },
}));

const StoreDailyBonus = () => {
    const [rows, setRows] = useState([]); // Initially empty table
    const [userId, setUserId] = useState(''); // For search input
    const [username, setUsername] = useState(''); // For search input
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // Define table columns based on your requirements
    const columns = [
        { field: 'userId', headerName: 'User ID', flex: 1, minWidth: 70 },
        { field: 'username', headerName: 'Username', flex: 1, minWidth: 100 },
        { field: 'spinBalanceSum', headerName: 'Spin Balance Sum', flex: 1, minWidth: 150 },
        { field: 'wheelSpinCount', headerName: 'Wheel Spin Count', flex: 1, minWidth: 150 },
    ];

    // Function to handle the search operation
    const handleSearch = useCallback(async () => {
        setIsLoading(true);

        // Prepare payload for the search API
        const payload = {
            searchParams: [
                { key: 'userId', value: userId },
                { key: 'username', value: username },
            ],
        };

        try {
            const response = await api.post('/v1/admin/user/players-bonus', payload);

            if (response.data.length === 0) {
                toast.info("No data found for the given search criteria.");
                setRows([]); // Clear previous results
            } else {
                setRows(response.data); // Set new data to show in the table
            }
        } catch (error) {
            if (error.response?.status === 403) {
                handle403Error(navigate);
            } else {
                toast.error("Failed to fetch data. Please try again later.");
            }
        } finally {
            setIsLoading(false);
        }
    }, [userId, username, navigate]);

    // Function to reset the search form and table data
    const handleReset = () => {
        setUserId('');
        setUsername('');
        setRows([]); // Clear table data
    };

    return (
        <div>
            <ToastContainer />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1 className="h3"><strong>Daily Bonus</strong></h1>
                </div>
            </div>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretch', sm: 'center' }}
                marginBottom={2}
            >
                <TextField
                    label="User ID"
                    variant="outlined"
                    size="small"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    style={{ marginRight: '8px', marginBottom: '8px' }}
                />
                <TextField
                    label="Username"
                    variant="outlined"
                    size="small"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ marginRight: '8px', marginBottom: '8px' }}
                />
                <SearchButton onClick={handleSearch} disabled={isLoading}>
                    {isLoading ? 'Searching...' : 'Search'}
                </SearchButton>
                <ResetButton onClick={handleReset}>
                    Reset
                </ResetButton>
            </Box>

            {/* Conditionally render the DataTable if rows contain data */}
            {rows.length > 0 && (
                <div>
                    <DataTable rows={rows} columns={columns} />
                </div>
            )}
        </div>
    );
};

export default StoreDailyBonus;
