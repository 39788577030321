import React from 'react';
import { useLocation } from 'react-router-dom';

const Profile = () => {
    const location = useLocation();
    const userDetails = location.state?.userDetails;

    if (!userDetails) {
        return <div>Loading...</div>;
    }

    return (
        <main style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f4f4f9' }}>
            <div style={{ maxWidth: '800px', width: '100%', padding: '20px', backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
                <div className="mb-3">
                    <h1 className="h3" style={{ textAlign: 'center', color: '#333', marginBottom: '20px' }}>Profile</h1>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <div className="card-body text-center" style={{ flex: '1', margin: '10px' }}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" alt="User Avatar" className="img-fluid rounded-circle" style={{ width: '128px', height: '128px', marginBottom: '20px' }} />

                        <div className="text-muted mb-2" style={{ fontSize: '1.25rem', fontWeight: '600' }}>Name: {userDetails.userName}</div>
                        <div className="text-muted mb-2" style={{ fontSize: '1.25rem', fontWeight: '600' }}>Id: {userDetails.id}</div>
                    </div>
                    <div style={{ flex: '1', padding: '20px', margin: '10px' }}>
                        <div className="text-muted mb-2" style={{ fontSize: '0.9rem', color: '#6c757d', marginBottom: '10px' }}>Nick Name: {userDetails.nickName}</div>
                        <div className="text-muted mb-2" style={{ fontSize: '0.9rem', color: '#6c757d', marginBottom: '10px' }}>Total Balance: {userDetails.totalBalance}</div>
                        <div className="text-muted mb-2" style={{ fontSize: '0.9rem', color: '#6c757d', marginBottom: '10px' }}>User Status: {userDetails.userStatus}</div>
                        <div className="text-muted mb-2" style={{ fontSize: '0.9rem', color: '#6c757d', marginBottom: '10px' }}>Role: {userDetails.role}</div>
                        <div className="text-muted mb-2" style={{ fontSize: '0.9rem', color: '#6c757d', marginBottom: '10px' }}>Creator Id: {userDetails.creatorId}</div>
                        <div className="text-muted mb-2" style={{ fontSize: '0.9rem', color: '#6c757d', marginBottom: '10px' }}>Created Date: {userDetails.createdDate}</div>
                        <div className="text-muted mb-2" style={{ fontSize: '0.9rem', color: '#6c757d', marginBottom: '10px' }}>Updated Date: {userDetails.updatedDate}</div>
                        <div className="text-muted mb-2" style={{ fontSize: '0.9rem', color: '#6c757d', marginBottom: '10px' }}>Last Login: {userDetails.lastLogin}</div>
                    </div>
                </div>
            </div>
            <style>
                {`
                    @media (max-width: 768px) {
                        .profile-container {
                            flex-direction: column !important;
                        }
                    }
                `}
            </style>
        </main>
    );
};

export default Profile;
