import {
    Button,
} from '@mui/material';

import { styled } from '@mui/system';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error'; // Import the shared function

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
    withCredentials: true,
});


const CustomButton = styled(Button)(({ theme }) => ({
    flex: 1,
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover': {
        backgroundColor: '#115293',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        padding: '8px 16px',
        minWidth: 'auto',
        width: '100%',
        fontSize: '12px',
    },
}));


const CreateForm = ({ onClose, onAddUser }) => {
    const [account, setAccount] = useState('');
    const [nickName, setNickName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [rechargeAmount, setRechargeAmount] = useState('');
    const [formHeading, setFormHeading] = useState('Add User');
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);
    const [errors, setErrors] = useState({
        account: '',
        nickName: '',
        password: '',
        confirmPassword: '',
        rechargeAmount: '',
    });
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem('role');
        if (role === 'STORE') {
            setFormHeading('Add Player');
        } else if (role === 'SUB_DISTRIBUTOR') {
            setFormHeading('Add Store');
        } else if (role === 'DISTRIBUTOR') {
            setFormHeading('Add Sub-Dist');
        } else if (role === 'MASTER') {
            setFormHeading('Add Distributor');
        }
    }, []);

    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisibility(!confirmPasswordVisibility);
    };

    const validateForm = () => {
        let isValid = true;
        let newErrors = {
            account: '',
            nickName: '',
            password: '',
            confirmPassword: '',
            rechargeAmount: '',
        };

        if (!account.trim()) {
            newErrors.account = 'Cannot be empty';
            isValid = false;
        }
        if (!nickName.trim()) {
            newErrors.nickName = 'Cannot be empty';
            isValid = false;
        }
        if (!password.trim()) {
            newErrors.password = 'Cannot be empty';
            isValid = false;
        }
        if (confirmPassword !== password) {
            newErrors.confirmPassword = 'Passwords do not match';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (loading) return; // Prevent multiple clicks
        setLoading(true); // Set loading to true to disable the button

        if (!validateForm()) {
            setLoading(false); // Reset loading state on error
            return;
        }

        try {
            const encodedPassword = btoa(password); // Encode the password
            const response = await api.post(
                'v1/admin/user/create',
                {
                    account,
                    nickName,
                    password: encodedPassword,
                },
            );

            const responseData = response.data;

            if (responseData.success) {
                const newUser = responseData.data;

                if (rechargeAmount && !isNaN(parseFloat(rechargeAmount))) {
                    await handleRecharge(account, newUser, responseData.message);
                } else {
                    onAddUser(newUser, responseData.message); // Pass the success message to the parent component
                    onClose(); // Close the form after successful user creation
                }
            }
        } catch (error) {
            handle403Error(error, navigate);
        } finally {
            setLoading(false); // Reset loading state after the API call completes
        }
    };

    const handleRecharge = async (username, newUser, createMessage) => {
        if (!rechargeAmount || isNaN(parseFloat(rechargeAmount))) {
            toast.error('Invalid recharge amount');
            return;
        }

        try {
            const response = await api.post(
                '/v1/admin/transact/recharge',
                {
                    username,
                    balance: parseFloat(rechargeAmount),
                },
            );

            if (response.status === 200) {
                newUser.totalBalance = parseFloat(rechargeAmount);
                const rechargeMessage = response.data.message || 'Recharge successful!';
                onAddUser(newUser, createMessage, rechargeMessage); // Pass both success messages to the parent component
                onClose(); // Close the form after successful recharge
            } else {
                handle403Error(response, navigate);
            }
        } catch (error) {
            onAddUser(newUser, createMessage); // Pass the success message to the parent component
            onClose(); // Close the form even if recharge fails
            handle403Error(error, navigate);
        }
    };

    const styles = {
        form: {
            width: '400px',
            padding: '20px',
            background: 'white',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: 2,
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        inputGroup: {
            marginBottom: '15px',
        },
        label: {
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            fontSize: '14px',
        },
        input: {
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ced4da',
        },
        errorText: {
            color: 'red',
            fontSize: '12px',
        },
        buttonGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        button: {
            flex: 1,
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            marginBottom: '10px',
        },
        submitButton: {
            backgroundColor: '#007bff',
            color: 'white',
            marginRight: '0',
        },
        cancelButton: {
            backgroundColor: '#f44336',
            color: 'white',
            border: '1px solid #ced4da',
        },
        buttonGroupRow: {
            flexDirection: 'row',
        },
        buttonMarginRight: {
            marginRight: '10px',
        },
        passwordWrapper: {
            position: 'relative',
        },
        togglePassword: {
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
        },
    };

    const isDesktop = window.innerWidth >= 600;

    const handleAccountChange = (e) => {
        setAccount(e.target.value);
        if (errors.account && e.target.value) {
            setErrors({ ...errors, account: '' });
        }
    };

    const handleNickNameChange = (e) => {
        setNickName(e.target.value);
        if (errors.nickName && e.target.value) {
            setErrors({ ...errors, nickName: '' });
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (errors.password && e.target.value) {
            setErrors({ ...errors, password: '' });
        }
        if (errors.confirmPassword && e.target.value === confirmPassword) {
            setErrors({ ...errors, confirmPassword: '' });
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (errors.confirmPassword && e.target.value) {
            setErrors({ ...errors, confirmPassword: '' });
        }
        if (errors.password && e.target.value === password) {
            setErrors({ ...errors, password: '' });
        }
    };

    const handleRechargeAmountChange = (e) => {
        const value = e.target.value;
    
        // Allow only numbers and a single dot, and limit to two decimal places
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setRechargeAmount(value);
        }
    
        // Clear the error message if the value is valid and non-empty
        if (errors.rechargeAmount && value) {
            setErrors({ ...errors, rechargeAmount: '' });
        }
    };
    

    return (
        <div style={styles.form}>
            <h2 style={styles.header}>{formHeading}</h2>
            <form onSubmit={handleSubmit}>
                <div style={styles.inputGroup}>
                    <label style={styles.label} htmlFor="account">
                        {formHeading.includes('Player')
                            ? 'Player Name'
                            : formHeading.includes('Sub-Dist')
                                ? 'Sub-Dist Name'
                                : formHeading.includes('Store')
                                    ? 'Store Name'
                                    : formHeading.includes('Distributor')
                                        ? 'Distributor Name'
                                        : 'User Name'}
                    </label>
                    <input
                        id="account"
                        type="text"
                        placeholder="Enter Name"
                        style={styles.input}
                        value={account}
                        onChange={handleAccountChange}
                    />
                    {errors.account && <div style={styles.errorText}>{errors.account}</div>}
                </div>

                <div style={styles.inputGroup}>
                    <label style={styles.label} htmlFor="nickName">Nick Name</label>
                    <input
                        id="nickName"
                        type="text"
                        placeholder="Enter a Nick Name"
                        style={styles.input}
                        value={nickName}
                        onChange={handleNickNameChange}
                    />
                    {errors.nickName && <div style={styles.errorText}>{errors.nickName}</div>}
                </div>

                <div style={styles.inputGroup}>
                    <label style={styles.label} htmlFor="password">Login Password</label>
                    <div style={styles.passwordWrapper}>
                        <input
                            id="password"
                            type={passwordVisibility ? 'text' : 'password'}
                            placeholder="Enter Password"
                            style={styles.input}
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <button
                            type="button"
                            style={styles.togglePassword}
                            onClick={togglePasswordVisibility}
                            aria-label="Toggle password visibility"
                        >
                            <FontAwesomeIcon icon={passwordVisibility ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    {errors.password && <div style={styles.errorText}>{errors.password}</div>}
                </div>

                <div style={styles.inputGroup}>
                    <label style={styles.label} htmlFor="confirmPassword">Confirm Password</label>
                    <div style={styles.passwordWrapper}>
                        <input
                            id="confirmPassword"
                            type={confirmPasswordVisibility ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            style={styles.input}
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        <button
                            type="button"
                            style={styles.togglePassword}
                            onClick={toggleConfirmPasswordVisibility}
                            aria-label="Toggle confirm password visibility"
                        >
                            <FontAwesomeIcon icon={confirmPasswordVisibility ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    {errors.confirmPassword && <div style={styles.errorText}>{errors.confirmPassword}</div>}
                </div>

                <div style={styles.inputGroup}>
                    <label style={styles.label} htmlFor="rechargeAmount">Recharge Amount</label>
                    <input
                        id="rechargeAmount"
                        type="text"
                        placeholder="Enter Recharge Amount (Optional)"
                        style={styles.input}
                        value={rechargeAmount}
                        onChange={handleRechargeAmountChange}
                    />
                    {errors.rechargeAmount && <div style={styles.errorText}>{errors.rechargeAmount}</div>}
                </div>

                <div style={{ ...styles.buttonGroup, ...(isDesktop ? styles.buttonGroupRow : {}) }}>
                    <CustomButton
                        type="submit"
                        style={{ ...styles.button, ...styles.submitButton, ...(isDesktop ? styles.buttonMarginRight : {}) }}
                        disabled={loading} // Disable button when loading
                    >
                        {'Submit'}
                    </CustomButton>
                    <CustomButton
                        type="button"
                        style={{ ...styles.button, ...styles.cancelButton }}
                        onClick={onClose}
                    >
                        Cancel
                    </CustomButton>
                </div>
            </form>
        </div>
    );
};

export default CreateForm;
